import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useConfig } from './config';
import { useSSOStore } from './store/sso';

interface AxiosConfigProps {
  disableAuth?: boolean;
}

const AxiosConfig: React.FC<AxiosConfigProps> = ({ disableAuth, children }) => {
  const senseviewUri = useConfig((state) => state.api.senseview.api_uri);
  const { token, setToken } = useSSOStore(
    useCallback(({ token, setToken }) => ({ token, setToken }), []),
  );

  const [intercepted, setIntercepted] = useState(false);

  const interceptor = useCallback(
    (request: AxiosRequestConfig): AxiosRequestConfig => {
      if (request.url && request.url.includes('/public/')) {
        return request;
      }
      if (!token) {
        return request;
      }

      return {
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${token?.jwt}`,
        },
      };
    },
    [token],
  );

  useEffect(() => {
    axios.defaults.baseURL = senseviewUri;

    if (disableAuth) {
      setIntercepted(true);
      return () => {
        setIntercepted(false);
      };
    }

    // console.log('adding axios interceptors');
    const requestInterceptor = axios.interceptors.request.use(interceptor);
    // const responseInterceptor = axios.interceptors.response.use(
    //   undefined,
    //   (error) =>
    //     (!error.response || error.response.status === 401) && setToken(), // 401 error => logout
    // );
    const responseInterceptor = axios.interceptors.response.use(
      undefined,
      (error) => {
        if (!error.response) {
          throw new Error('CORS error');
        }
        console.log('interceptor error', error);
        if (error.response?.status === 401) {
          setToken();
        }
      }, // 401 error => logout
    );
    setIntercepted(true);

    return () => {
      // console.log('clearing axios interceptors');
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
      setIntercepted(false);
    };
  }, [senseviewUri, disableAuth, interceptor, setToken]);

  if (!intercepted) {
    return null;
  }

  return <>{children}</>;
};

export default AxiosConfig;
