import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import SenseViewTheme from './theme/SenseViewTheme';

ReactDOM.render(
  <React.StrictMode>
    <SenseViewTheme>
      <App />
    </SenseViewTheme>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement,
);
