import produce from 'immer';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { ServerDeviceData } from '../models/DataPoint';

interface DataPointStore {
  data: Record<string, Record<string, ServerDeviceData>>;
  load: (data: ServerDeviceData[]) => void;
  add: (data: ServerDeviceData) => void;
  addMany: (dataPoints: ServerDeviceData[]) => void;
}

export const useDataPoints = create<DataPointStore>(
  devtools(
    (set) => ({
      data: {},
      load: (data) => {
        set(
          produce((state) => {
            data.forEach((item) => {
              if (state.data[item.device_id] === undefined) {
                state.data[item.device_id] = {};
              }

              state.data[item.device_id][
                item.model_id === 'transform'
                  ? `transform:${item.sensor_id}`
                  : item.sensor_id
              ] = item;
            });
          }),
        );
      },
      add: (data) => {
        set(
          produce((state) => {
            if (state.data[data.device_id] === undefined) {
              state.data[data.device_id] = {};
            }

            const sensorId =
              data.model_id === 'transform'
                ? `transform:${data.sensor_id}`
                : data.sensor_id;

            if (
              state.data[data.device_id][sensorId] &&
              state.data[data.device_id][sensorId].time > data.time
            ) {
              return;
            }
            state.data[data.device_id][sensorId] = data;
          }),
        );
      },
      addMany: (dataPoints: ServerDeviceData[]) => {
        set(
          produce((state) => {
            dataPoints.forEach((data) => {
              if (state.data[data.device_id] === undefined) {
                state.data[data.device_id] = {};
              }

              const sensorId =
                data.model_id === 'transform'
                  ? `transform:${data.sensor_id}`
                  : data.sensor_id;

              if (
                state.data[data.device_id][sensorId] &&
                state.data[data.device_id][sensorId].time > data.time
              ) {
                return;
              }
              state.data[data.device_id][sensorId] = data;
            });
          }),
        );
      },
    }),
    { name: 'datapoints' },
  ),
);

const buffer = {
  points: [] as ServerDeviceData[],
  updatedAt: null as DateTime | null,
  timeout: null as ReturnType<typeof setTimeout> | null,
};

export const useAddDataPoint = () => {
  const addToStore = useDataPoints(useCallback(({ addMany }) => addMany, []));

  const flushBuffer = useCallback(() => {
    // console.log('flushBuffer', buffer);

    if (buffer.timeout !== null) {
      // console.log('clearing flush timer');
      clearTimeout(buffer.timeout);
      buffer.timeout = null;
    }

    if (buffer.points.length > 0) {
      addToStore(buffer.points);
    }

    // Reset buffer
    buffer.points = [];
    buffer.updatedAt = null;
  }, [addToStore]);

  return useCallback(
    (dataPoint: ServerDeviceData) => {
      // console.log('adding datapoint to buffer');
      buffer.points.push(dataPoint);
      if (buffer.points.length >= 10) {
        flushBuffer();
      }
      if (buffer.updatedAt === null) {
        buffer.updatedAt = DateTime.local();
        buffer.timeout = setTimeout(flushBuffer, 3000);
      }
    },
    [flushBuffer],
  );
};
